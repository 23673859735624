import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Sidebar from './components/Sidebar';
import Dashboard from './pages/Dashboard';
import Settings from './pages/Settings';
import LanguageSelector from './components/LanguageSelector';
import './i18n';
import './App.css';
import './styles/Sidebar.css';

function App() {
  const { t } = useTranslation();

  return (
    <Router>
      <div className="app-container">
        <Sidebar />
        <main className="content">
          <LanguageSelector />
          <Routes>
            <Route path="/" element={<Dashboard />} />
            <Route path="/settings" element={<Settings />} />
          </Routes>
        </main>
      </div>
    </Router>
  );
}

export default App;