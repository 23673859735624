import React from 'react';
import { useTranslation } from 'react-i18next';

function Settings() {
  const { t } = useTranslation();

  return (
    <div className="settings bg-gray-100 min-h-screen p-8">
      <h1 className="text-4xl font-bold text-gray-800 mb-6">{t('settings')}</h1>
      <div className="bg-white rounded-lg shadow-md p-6">
        <p className="text-lg text-gray-700 mb-6">{t('manageSettings')}</p>

        <div className="space-y-6">
          {/* Example setting sections */}
          <div className="border-b pb-4">
            <h2 className="text-xl font-semibold text-gray-800 mb-3">{t('accountSettings')}</h2>
            <div className="flex items-center justify-between">
              <span className="text-gray-600">{t('emailNotifications')}</span>
              <label className="switch">
                <input type="checkbox" className="sr-only peer" />
                <span className="slider bg-gray-200 peer-checked:bg-blue-600 rounded-full w-11 h-6 inline-block relative cursor-pointer transition-colors ease-in-out duration-200 before:content-[''] before:absolute before:top-0.5 before:left-0.5 before:bg-white before:rounded-full before:w-5 before:h-5 before:transition-transform before:duration-200 before:ease-in-out peer-checked:before:translate-x-5"></span>
              </label>
            </div>
          </div>

          <div className="border-b pb-4">
            <h2 className="text-xl font-semibold text-gray-800 mb-3">{t('privacySettings')}</h2>
            <div className="space-y-2">
              <div className="flex items-center">
                <input type="checkbox" id="publicProfile" className="form-checkbox h-5 w-5 text-blue-600" />
                <label htmlFor="publicProfile" className="ml-2 text-gray-700">{t('makeProfilePublic')}</label>
              </div>
              <div className="flex items-center">
                <input type="checkbox" id="showEmail" className="form-checkbox h-5 w-5 text-blue-600" />
                <label htmlFor="showEmail" className="ml-2 text-gray-700">{t('showEmailToOthers')}</label>
              </div>
            </div>
          </div>

          <div>
            <h2 className="text-xl font-semibold text-gray-800 mb-3">{t('languagePreference')}</h2>
            <select className="form-select mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm rounded-md">
              <option>{t('english')}</option>
              <option>{t('french')}</option>
              {/* Add more language options as needed */}
            </select>
          </div>
        </div>

        <button className="mt-8 bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline transition duration-150 ease-in-out">
          {t('saveSettings')}
        </button>
      </div>
    </div>
  );
}

export default Settings;