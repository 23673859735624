import React from 'react';
import { useTranslation } from 'react-i18next';

function Dashboard() {
  const { t } = useTranslation();

  return (
    <div className="dashboard bg-gray-100 min-h-screen p-8">
      <h1 className="text-4xl font-bold text-gray-800 mb-6">{t('Dashboard')}</h1>
      <div className="bg-white rounded-lg shadow-md p-6">
        <p className="text-lg text-gray-700 mb-4">{t('Welcome to your dashboard')}</p>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
          {/* Example dashboard widgets */}
          <div className="bg-blue-100 p-4 rounded-lg">
            <h2 className="text-xl font-semibold text-blue-800 mb-2">{t('Widget 1')}</h2>
            <p className="text-blue-600">{t('Content for Widget 1')}</p>
          </div>
          <div className="bg-green-100 p-4 rounded-lg">
            <h2 className="text-xl font-semibold text-green-800 mb-2">{t('Widget 2')}</h2>
            <p className="text-green-600">{t('Content for Widget 2')}</p>
          </div>
          <div className="bg-yellow-100 p-4 rounded-lg">
            <h2 className="text-xl font-semibold text-yellow-800 mb-2">{t('Widget 3')}</h2>
            <p className="text-yellow-600">{t('Content for Widget 3')}</p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Dashboard;