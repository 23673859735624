import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

function Sidebar() {
  const { t } = useTranslation();

  return (
    <nav className="sidebar">
      <ul>
        <li><Link to="/">{t('dashboard')}</Link></li>
        <li><Link to="/settings">{t('settings')}</Link></li>
      </ul>
    </nav>
  );
}

export default Sidebar;